<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['acneTypes'])">
      <multi-picture-select
        title="label.pimpleTypes"
        :value="acneTypes"
        :options="$options.acneTypeOptions"
        dysfunction="pimples"
        @input="onFieldChange('acneTypes', $event)"
      />
    </question-with-error-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="hasNodules"
        class="q-centered q-mt8 q-mt16-md"
        :error="getFieldsError(['concurrentNodules'])"
      >
        <single-answer-question
          id="concurrent-nodules"
          title="label.concurrentNodules"
          :value="concurrentNodules"
          :options="$options.concurrentNodulesOptions"
          dysfunction="pimples"
          @input="onFieldChange('concurrentNodules', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import MultiPictureSelect from '@/modules/questionnaire/new-design-components/questions/MultiPictureSelect';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { NODULE_COUNT } from '@/modules/questionnaire/api/constants';
import { ACNE_TYPE_OPTIONS } from '@/modules/questionnaire/constants/steps/pimples';

const CONCURRENT_NODULES_OPTIONS = [
  { value: NODULE_COUNT.ONE, text: 'concurrentNodules.one' },
  { value: NODULE_COUNT.TWO, text: 'concurrentNodules.twoOrMore' }
];

export default {
  name: 'TypeTemplate',
  components: {
    FadeInTransitionWrap,
    QuestionWithErrorWrap,
    MultiPictureSelect,
    SingleAnswerQuestion
  },
  acneTypeOptions: ACNE_TYPE_OPTIONS,
  concurrentNodulesOptions: CONCURRENT_NODULES_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    acneTypes: {
      type: Array,
      default: () => []
    },
    concurrentNodules: {
      type: String,
      default: ''
    },
    hasNodules: {
      type: Boolean,
      default: false
    }
  }
};
</script>
